<template>
  <div>
    <ol class="optional-section">
      <FrameItemOptional
        v-for="(i, index) in optionalList"
        :key="index"
        :frame="i.frame"
        :title="i.title"
        :scalable="i.scalable"
        :frameKey="i.key"
        :uploadImages="uploadImage"
        @uploadPhoto="uploadPhoto"
        @deleteImg="deleteImg"
        @rotateImg="rotateImg"
        @initImgUpload="initImgUpload"
      />
      <template v-for="(image) in uploadImageOptionalCheck">
        <li
          v-if="image.key.includes('addOthers')"
          class="frame-item"
          :key="image.id"
          :style="authenticateData.listed || image.isAutoplay ? 'cursor: default;' : ''"
        >
          <b-overlay
            :show="image.onUpload"
            no-wrap
            spinner-variant="success"
            :opacity="0.5"
          ></b-overlay>
          <img
            width="100%"
            :src="image.url"
            class="uploaded-image"
          />
          <b-progress
            v-if="image.progress && image.progress !== 100"
            class="img-upload-progress"
            :value="image.progress"
            variant="success"
            striped
          ></b-progress>
          <div class="edit-options-list" v-show="!image.onUpload">
            <v-touch v-show="!image.isAutoplay && !authenticateData.listed" v-on:tap="rotateImg(image)">
              <custom-icon
                name="roll"
                class="edit-icon"
                width="100%"
                @click="rotateImg(image)"
              />
            </v-touch>
            <v-touch v-show="!image.isAutoplay && !authenticateData.listed" v-on:tap="deleteImg(image.filename, image.key)">
              <custom-icon
                name="delete"
                class="edit-icon"
                width="100%"
                :m0="true"
                @click="deleteImg(image.filename, image.key)"
              />
            </v-touch>
          </div>
        </li>
      </template>
      <li
        class="upload-item-optional photo-list-optional"
        style="position: relative"
        :style="{
          filter: authenticateData.listed ? 'grayscale(100%)' : 'none',
          cursor: authenticateData.listed ? 'default!important' : 'pointer',
          display: (uploadImageOptionalLengthCheck < this.maxLength) ? 'block' : 'none',
        }"
      >
        <img :src="theme.otherPhotoUploadIcons" class="optional-other-icon" />
        <div
           class="image-input-wrapper"
          style="width: 100%; height: 100%"
          @click="initImgUploadFileOptional()"
        >
          <input
            ref="fileinputOptional"
            type="file"
            id="files"
            name="files"
            style="display: none"
            multiple
            @change="uploadOtherPhoto"
            v-if="!imageDeleting"
            accept="image/*"
          />
          <div
            class="cover-text-wrapper"
            style="
              position: absolute;
              top: 72%;
              left: 50%;
              margin: 0 !important;
              padding: 0 !important;
              transform: translate(-50%, -50%);
              width: 100%;
            "
          >
            <div class="frame-title fs-10 font-weight-thin text-white">
              OTHER
            </div>
            <button type="button" class="photo-text">
              {{isMobile && !isSpecialDeviceBrowser ? 'ADD/TAKE PHOTOS' : 'ADD PHOTOS'}}
            </button>
            <div
              class="fs-14 cover-description-text font-weight-thin"
            >
              Supports: jpeg, png, tiff
            </div>
          </div>
        </div>
     </li>
    </ol>
    <PhotoUploadOptions
      :checkReadonly="checkReadonly"
      :imageDeleting="imageDeleting"
      :isAccessingCamera="isAccessingCamera"
      @initImgUploadCamera="initImgUploadCamera"
      ref="optionalPhotoOptions"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { optionalList } from '@/config/constants';
import { socialNetworkBrowser, browserList } from "@/helper/constant";
export default {
  name: "OptionalSection",
  data() {
    return {
      isMobile: true,
      optionalList,
      isSpecialDeviceBrowser: false,
      isValidImage: true,
      maxLength: 22,
    };
  },
  model: {
    prop: "uploadImage",
    event: "change",
  },
  props: {
     uploadImage: {
      type: Array,
      default: () => [],
    },
    uploadImageOptionalCheck: {
      type: Array,
      default: () => [],
    },
    imageDeleting: {
      type: Boolean,
      default: false
    },
    uploadImageOptionalLengthCheck: {
      type: Number,
      default: 0
    },
    isAccessingCamera: Boolean,
    checkReadonly: Boolean,
  },
  mounted () {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isAndroidSpecialBrowser = userAgent.includes('Android') && socialNetworkBrowser.some(browser => userAgent.includes(browser));
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);
      
      if (isAndroidSpecialBrowser) {
        this.isSpecialDeviceBrowser = true;
        this.isDialogPhotoUploadIssueOpen = true;
      } else if (isiOSChromeBrowser) {
        this.isSpecialDeviceBrowser = true;
      }
    }
  },
  methods: {
    initImgUploadFileOptional() {
      this.$refs.fileinputOptional.click();
    },
    async uploadOtherPhoto(event) {
      var input = event.target;
      if (input.files.length > 0) {
        const others = this.uploadImageOptionalCheck.filter((item) => item.key.includes('addOthers')).map((item) => item.key);
        if (others.length === 0) {
          await this.uploadPhoto(input.files[0], 'addOthers1', 'OTHER')
        } else {
          others.sort();
          const maxId = others.slice(-1)[0].replace('addOthers', '');
          await this.uploadPhoto(input.files[0], `addOthers${parseInt(maxId) + 1}`, 'OTHER')
        }
      }
    },
    uploadPhoto: async function (file, key, title) {
      await this.handleValidateImage(file);
      if (this.isValidImage) {
        const imgId = this.$uuid.v4();
        const newUploadImage = [
          ...this.uploadImage,
          {
            id: imgId,
            filename: file.name,
            url: null,
            name: file.name,
            key,
            title,
            onUpload: true,
            progress: 0,
            isAutoplay: false,
          },
        ];
        await this.$emit("change", newUploadImage);
        await this.$emit("handleImgUpload", file, imgId);
      }
    },
    async handleValidateImage(file) {
      await this.$emit("imageValidate", file, this.validateImageCallback);
    },
    validateImageCallback(result) {
      this.isValidImage = result;
    },
    deleteImg(filename, key) {
      this.$emit("deleteImg", filename, key);
    },
    rotateImg(item) {
      this.$emit("rotate", item);
    },
    initImgUpload() {
      this.$refs.optionalPhotoOptions.initImgUpload('optional');
    },
    initImgUploadCamera() {
      this.$emit("initImgUploadCamera");
    },
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
      theme: (state) => state.commonVariables.theme,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 900px)");
    },
    // isOtherOptionalItemAdded() {
    //   if (this.uploadImageOptionalCheck.find((image) => image.key === 'interiorOther4')) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  components: {
    FrameItemOptional: () => import("./FrameItemOptional.vue"),
    CustomIcon: () => import("../common/CustomIcon"),
    PhotoUploadOptions: () => import("../photo-upload/PhotoUploadOptions.vue"),
  },
};
</script>
<style lang="scss">
.optional-section {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding-inline-start: 0;
  .upload-item-optional {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .optional-other-icon {
      position: absolute;
      height: 26px !important;
      width: 26px !important;
    }
  }
}

@media screen and(min-width: 900px) {
  .optional-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and(min-width: 992px) {
  .optional-section {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and(min-width: 1200px) {
  .optional-section {
    grid-template-columns: repeat(5, 169px);
    gap: 20px;
    column-gap: 29px;
  }
}
</style>